import { createSignal, Show } from "solid-js";
import Map from "../../shared/Map";

import "./GeographyMap.css";

import IconExpandLess from "~/img/icons/expand_less.svg";
import IconLocationOn from "~/img/icons/location_on.svg";
import type { ProgramRefWithLocation } from "~/types/drupal_jsonapi";

export default function GeographyMap(props: {
  programs: ProgramRefWithLocation[];
}) {
  const [isActive, setIsActive] = createSignal(false);

  return (
    <>
      <div
        class="programs-map"
        data-ga-zone="map"
        classList={{ active: isActive() }}
      >
        <Show when={isActive()}>
          <Map programs={props.programs} />
        </Show>
        <div class="expand">
          <div class="content-part">
            <span class="expand-btn">
              <button
                type="button"
                class="btn btn-icon"
                aria-label={
                  isActive() ? "Replier la carte" : "Déplier la carte"
                }
                onClick={() => setIsActive(!isActive())}
              >
                <i aria-hidden="true" class="cog-icon">
                  <Show when={isActive()} fallback={<IconLocationOn />}>
                    <IconExpandLess />
                  </Show>
                </i>
              </button>
              <span>Carte</span>
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
