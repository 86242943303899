import type { QA } from "~/types/drupal_jsonapi";
import { createSignal, For, Show } from "solid-js";

import "./GeographySEO.css";

import IconExpandMore from "~/img/icons/expand_more.svg";

export default function GeographySEO(props: {
  description?: string;
  qas: QA[] | undefined;
}) {
  const [isExpanded, setIsExpanded] = createSignal(false);

  return (
    <>
      <section
        class="geography-seo"
        classList={{ expanded: isExpanded() }}
        data-test="seo-block-geo"
      >
        <div class="content-part">
          <div class="description-wrapper">
            <Show when={props.description}>
              <div innerHTML={props.description!} />

              <Show when={props.qas && props.qas.length > 0}>
                <section
                  class="geography-faq"
                  itemscope
                  itemtype="https://schema.org/FAQPage"
                >
                  <h2>Foire aux questions</h2>
                  <For each={props.qas}>
                    {(qa: QA) => (
                      <>
                        <Show when={qa.field_question && qa.field_answer}>
                          <div
                            itemscope
                            itemprop="mainEntity"
                            itemtype="https://schema.org/Question"
                            class="paragraph-faq"
                            data-test="faq"
                          >
                            <Show when={qa.field_question}>
                              <h3 itemprop="name">{qa.field_question}</h3>
                            </Show>
                            <Show when={qa.field_answer.value}>
                              <div
                                itemscope
                                itemprop="acceptedAnswer"
                                itemtype="https://schema.org/Answer"
                              >
                                <div
                                  itemprop="text"
                                  data-test="answer"
                                  innerHTML={qa.field_answer.value}
                                />
                              </div>
                            </Show>
                          </div>
                        </Show>
                      </>
                    )}
                  </For>
                </section>
              </Show>
            </Show>
          </div>
          <div class="more">
            <button
              type="button"
              class="btn btn-icon"
              aria-label={isExpanded() ? "Replier" : "Déplier"}
              onClick={() => setIsExpanded(!isExpanded())}
              data-test="btn-more"
            >
              <i aria-hidden="true" class="cog-icon chevron">
                <IconExpandMore />
              </i>
            </button>
          </div>
        </div>
      </section>
    </>
  );
}
