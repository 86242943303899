import { For, Show } from "solid-js";
import GeographyMap from "~/components/Geography/Components/GeographyMap";

import type { Geography, ItemReference } from "~/types/drupal_jsonapi";
import { urlRs } from "~/utils/url";

export default function GeographyHeading(props: { geography: Geography }) {
  return (
    <>
      <section class="heading" data-test="header">
        <div class="content-part">
          <h1>Programme immobilier neuf {props.geography.name}</h1>
          <Show
            when={
              props.geography.children && props.geography.children.length > 0
            }
          >
            <Show when={props.geography.geography_level === "city"}>
              <h2>
                Les quartiers {props.geography.field_prefix_2}
                {props.geography.name}
              </h2>
            </Show>
            <ul class="geography-list" data-test="children">
              <For each={props.geography.children}>
                {(children: ItemReference) => {
                  return (
                    <li>
                      <a
                        href={urlRs("geographies", children.url)}
                        hreflang="fr"
                      >
                        {children.title}
                      </a>
                    </li>
                  );
                }}
              </For>
            </ul>
          </Show>
        </div>
        <Show
          when={props.geography.programs && props.geography.programs.length > 0}
        >
          <GeographyMap programs={props.geography.programs!} />
        </Show>
      </section>
    </>
  );
}
