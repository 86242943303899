import { createSignal, For, Show } from "solid-js";
import { urlRs } from "~/utils/url";

import type { Geography } from "~/types/drupal_jsonapi";

import "./GeographyList.css";

import IconExpandMore from "~/img/icons/expand_more.svg";

export default function GeographyList(props: { geography: Geography }) {
  const [isExpanded, setIsExpanded] = createSignal(false);

  return (
    <>
      <section class="geographies">
        <div class="content-part">
          <Show
            when={
              props.geography.siblings && props.geography.siblings.length > 0
            }
          >
            <div class="geography-list inline" data-test="siblings">
              {/* Region */}
              <Show when={props.geography.geography_level === "region"}>
                <h2 class="title">
                  Retrouvez les programmes neufs dans d’autres régions
                </h2>
              </Show>
              {/* Department */}
              <Show when={props.geography.geography_level === "department"}>
                <h2 class="title">
                  Retrouvez nos programmes neufs dans d’autres départements{" "}
                  {props.geography.parent_geography?.prefix_2}
                  {props.geography.parent_geography?.name}
                </h2>
              </Show>
              {/* City */}
              <Show when={props.geography.geography_level === "city"}>
                <h2 class="title">
                  Retrouvez nos programmes neufs dans d’autres villes{" "}
                  {props.geography.parent_geography?.prefix_2}
                  {props.geography.parent_geography?.name}
                </h2>
              </Show>
              {/* Neighborhood */}
              <Show when={props.geography.geography_level === "neighborhood"}>
                <h2 class="title">
                  Retrouvez nos programmes neufs dans d’autres quartiers{" "}
                  {props.geography.parent_geography?.prefix_2}
                  {props.geography.parent_geography?.name}
                </h2>
              </Show>
              {/* Siblings */}
              <ul class="siblings">
                <For each={props.geography.siblings}>
                  {(sibling) => (
                    <li>
                      <a
                        href={urlRs("geographies", sibling.url)}
                        data-test="sibling"
                      >
                        {sibling.title}
                      </a>
                    </li>
                  )}
                </For>
              </ul>
            </div>
          </Show>

          {/* Region Top Cities */}
          <Show
            when={
              props.geography.geography_level === "region" &&
              props.geography.top_cities &&
              props.geography.top_cities.length > 0
            }
          >
            <div class="geography-list inline" data-test="top-cities">
              <h2 class="title">
                Le TOP des villes où investir {props.geography.field_prefix_1}
                {props.geography.name}
              </h2>
              <ul class="children">
                <For each={props.geography.top_cities}>
                  {(city) => (
                    <li>
                      <a href={urlRs("geographies", city.url)} data-test="city">
                        {city.title}
                      </a>
                    </li>
                  )}
                </For>
              </ul>
            </div>
          </Show>

          {/* Department Other Cities */}
          <Show
            when={
              props.geography.geography_level === "department" &&
              props.geography.other_cities_in_department &&
              props.geography.other_cities_in_department.length > 0
            }
          >
            <div class="geography-list inline" data-test="other-cities">
              <h2 class="title">
                Retrouvez nos programmes neufs dans d’autres villes{" "}
                {props.geography.field_prefix_1}
                {props.geography.name}
              </h2>
              <ul class="children">
                <For each={props.geography.other_cities_in_department}>
                  {(city) => (
                    <li>
                      <a href={urlRs("geographies", city.url)} data-test="city">
                        {city.title}
                      </a>
                    </li>
                  )}
                </For>
              </ul>
            </div>
          </Show>

          <Show
            when={
              props.geography.archived_programs &&
              props.geography.archived_programs.length > 0
            }
          >
            <div class="archives" classList={{ expanded: isExpanded() }}>
              <h2 class="title">Nos programmes archivés</h2>
              <div class="archives-wrapper">
                <ul data-test="programs">
                  <For each={props.geography.archived_programs}>
                    {(program) => (
                      <li>
                        <a
                          href={urlRs("programs", program.url)}
                          data-test="program"
                        >
                          {program.title}
                        </a>
                      </li>
                    )}
                  </For>
                </ul>
              </div>
              <div class="more">
                <button
                  type="button"
                  class="btn btn-icon"
                  aria-label={isExpanded() ? "Replier" : "Déplier"}
                  onClick={() => setIsExpanded(!isExpanded())}
                  data-test="btn-more"
                >
                  <i aria-hidden="true" class="cog-icon chevron">
                    <IconExpandMore />
                  </i>
                </button>
              </div>
            </div>
          </Show>
        </div>
      </section>
    </>
  );
}
