import GeographyHeading from "~/components/Geography/Components/GeographyHeading";
import GeographyPrograms from "~/components/Geography/Components/GeographyPrograms";
import GeographySEO from "~/components/Geography/Components/GeographySEO";
import { createMemo, lazy, Show, Suspense } from "solid-js";
import { useSearchParams } from "@solidjs/router";
import GeographyList from "~/components/Geography/Components/GeographyList";
import Breadcrumb from "~/components/shared/Breadcrumb";
import { urlRs } from "~/utils/url";
import { geographyBreadcrumbItemsForGeography } from "~/utils/helper_program";

import type { Geography } from "~/types/drupal_jsonapi";
import type { BreadcrumbItem } from "~/types/breadcrumb";

import "./Geography.css";
import { useLotActive } from "~/contexts/LotActiveContext";
import GtmVars from "../shared/Trackers/GtmVars";

const LotDrawer = lazy(
  () => import("~/components/Program/Components/LotDrawer"),
);

export function Geography(props: { geography: Geography }) {
  const [lotProvider] = useLotActive();
  const [searchParams] = useSearchParams();

  const breadcrumbItems = createMemo(() => {
    const items: BreadcrumbItem[] = [];

    const root: BreadcrumbItem = {
      href: urlRs("search", "/programme-immobilier-neuf/"),
      text: "Programmes immobiliers neufs",
    };
    items.push(root);

    const geographiesTemp: BreadcrumbItem[] = [];
    const geographies: BreadcrumbItem[] = geographyBreadcrumbItemsForGeography(
      props.geography.drupal_internal__tid,
      props.geography,
      geographiesTemp,
    ).toReversed();

    geographies.forEach((geography: BreadcrumbItem) => {
      items.push(geography);
    });

    const lastBreadCrumbItem: BreadcrumbItem = {
      text: props.geography.name,
    };
    items.push(lastBreadCrumbItem);

    return items;
  });

  const level = () => {
    switch (props.geography.geography_level) {
      case "region":
        return "1";
      case "department":
        return "2";
      case "city":
        return "3";
      default:
        return "4";
    }
  };

  const gtmValues = () => {
    return {
      "all.pageType": "listing page",
      "all.mainCategory": breadcrumbItems()[0].text,
      "all.category": breadcrumbItems()[1]?.text ?? "",
      "all.subCategory": breadcrumbItems()[2]?.text ?? "",
      "all.subsubCategory": breadcrumbItems()[3]?.text ?? "",
      "all.subsubsubCategory": breadcrumbItems()[4]?.text ?? "",
      ...props.geography.gtm_vars,
      template: `geography-${level()}`,
    };
  };

  return (
    <>
      <GtmVars values={gtmValues()} />
      <section class="geography-page">
        <Show when={lotProvider.wrapper && lotProvider.lot} keyed>
          <Suspense>
            <LotDrawer wrapper={lotProvider.wrapper!} lot={lotProvider.lot!} />
          </Suspense>
        </Show>
        <GeographyHeading geography={props.geography} />

        <GeographyPrograms geography={props.geography} />

        <Show
          when={
            (!searchParams.page || searchParams.page === "0") &&
            props.geography.description?.value
          }
        >
          <GeographySEO
            description={props.geography.description?.value}
            qas={props.geography.field_qas}
          />
        </Show>

        <GeographyList geography={props.geography} />
      </section>
      <Breadcrumb items={breadcrumbItems()} />
    </>
  );
}
